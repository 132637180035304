<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Arsip Keseluruhan </strong><small>Tambah Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="inputArsip">
            <CRow>
              <CCol sm="6">
              <CInput
                v-model="nomor_berkas"
                label="Nomor Berkas"
                placeholder="Input Nomor Berkas"
              />
              </CCol>
              <CCol sm="6">
              <div class="form-group">
                <label> Tahun</label>
                <select v-model="level" placeholder='Pilih Tahun' class="form-control">
                  <option value="" disabled selected>Pilih Tahun</option>
                  <option value="2022">2022</option>
                  <option value="2021">2021</option>
                </select>
                </div> 
              </CCol>
              <CCol sm="6">
              <div class="form-group">
                <label> Kategori</label>
                <select v-model="kategori" placeholder='Pilih Kategori' class="form-control">
                  <option value="" disabled selected>Pilih Kategori</option>
                  <option value="1">Umum</option>
                  <option value="2">Swasta</option>
                  <option value="3">Pemerintah</option>
                </select>
              </div> 
              </CCol>
              <CCol sm="6">
             <div class="form-group">
                <label> Rak</label>
                <select v-model="rak" placeholder='Pilih Rak' class="form-control">
                  <option value="" disabled selected>Pilih Rak</option>
                  <option value="A001">A001</option>
                  <option value="A002">A002</option>
                  <option value="A003">A003</option>
                </select>
              </div> 
              </CCol> 
              <CCol sm="6">
                <div class="form-group">
                <label> Nomor Hak</label>
                <select v-model="level" placeholder='Pilih Tanah' class="form-control">
                  <option value="" disabled selected>Pilih Tanah</option>
                  <option value="1">Administrator</option>
                  <option value="2">Kontraktor</option>
                </select>
                </div>
              </CCol>      
            </CRow>          
                <div class="form-actions">
                  <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                  <CButton color="secondary"  to="/pemerintah">Batal</CButton>
                </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data User gagal diinput.
    </CModal>
   
  </div>
</template>
<script>
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  data () {
    return {
      nomor_berkas : "",
      nama_lengkap : "",
      email : "",
      pass : "",
      level : "",
      selected: [], // Must be an array reference!
      show: true,
    }
  },
  methods: {
    // validator (val) {
    //   return val ? val.length >= 4 : false
    // },
    inputArsip: function(event){
      // Simple POST request with a JSON body using axios
      const user = { 
                          nomor_berkas:this.nomor_berkas,
                          nama_lengkap: this.nama_lengkap, 
                          email: this.email, 
                          pass: this.pass, 
                          level: this.level, 
                          };
      // alert(JSON.stringify(user));
      axios.post("https://sippd.probowsolution.com/api/public/index.php/adduser", user)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/user');
            }
            else{
              this.$root.$emit("bv::show::modal", "modalError");
            }            
        })
    }
  }
}
</script>
